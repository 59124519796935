






import Vue from 'vue';
import TermsofService from './TermsofService.vue'
export default Vue.extend({
    components: { TermsofService },
});
